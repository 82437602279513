import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import Swal from 'sweetalert2'
import router from '../../../router'
import store from '../../../store'

export default class THdata {
    constructor(){
    
      
    }
    getTHdata(obj,data,api){
        return new Promise(resolve=>{
            axios.post(`${data}thRanking`,obj,api).then(res=>{

                let plannerData =[]
                let SalesOfficeData=[]
                res.data.filter(rec=>{
                   rec.year = moment(rec.SentProcessRequestDate).format('YYYY')
                   rec.month = moment(rec.SentProcessRequestDate).format('MM')
                   if(rec.EmployeeCode == obj.EmployeeCode){
                     plannerData.push(rec)
                   }
                   if(rec.SalesOfficeCode == `${obj.SalesOfficeCode}`){
                     SalesOfficeData.push(rec)
                   }

                })
                let years = res.data.map(rec=>{
                 return rec.year
               })
               let filteredYear = _.sortedUniqBy(years , Math.floor)
               let headers = filteredYear.map(yr=>{ 
                    let months =  []
                    res.data.map(rec=>{ 
                        if(rec.year == yr){
                          months.push(rec.month)
                        }
                    })
                let sortedMonths = _.sortedUniqBy(months , Math.floor)
                    return {
                    year : yr,
                    months : sortedMonths
                    }
                })

//==========================PLANNERS======================================================================
            let planner = plannerData.reduce((total,item)=>{
             let index = total.findIndex(y =>  y.year == item.year && y.month == item.month)
                if(index >-1){
                    total[index].th += item.TH 
                    total[index].kakou += 1,
                    total[index].avg = Math.round(( total[index].th /  total[index].kakou)  * 10) / 10
                    // total[index].avg = total[index].avg == 0  ? 0 : (total[index].avg).toFixed(1),
                    total[index].item.push(item)
                    return total
                }
                else{  
                total.push({
                    year:item.year,
                    EmployeeCode:item.EmployeeCode,
                    month:item.month,
                    th:item.TH,
                    kakou : 1,
                    avg : item.TH,
                    item:[item]
                })
                return total
                }
            },[])
          
//=======================SALES OFFICE=========================================================================


    let SalesOffice = SalesOfficeData.reduce((total,item)=>{
      let index = total.findIndex(y =>   y.year == item.year && y.month == item.month)
      if(index >-1){
          total[index].th += item.TH 
          total[index].kakou += 1,
          total[index].avg = Math.round( ( total[index].th/  total[index].kakou)  * 10) / 10
          // total[index].avg = total[index].avg == 0  ? 0 : (total[index].avg).toFixed(1),
          total[index].item.push(item)
        return total
      }
      else{  
        total.push(
            {
              year:item.year,
              month:item.month,
              th:item.TH,
              kakou : 1,
              avg : item.TH,
              item:[item],
            }
          )
        return total
      }
    },[])



    SalesOffice.forEach(rec=>{
      rec.ranking = rec.item.reduce((total,item)=>{
        let index = total.findIndex(y => y.EmployeeCode==item.EmployeeCode )
          if(index > -1){
              total[index].TH += item.TH
            return total
          }else{
            total.push(
             { 
              EmployeeCode:item.EmployeeCode,
              TH:item.TH,
             }
            )
            return total
          }
      },[]).sort((a,b)=>{
        return a.TH - b.TH || a.EmployeeCode - b.EmployeeCode
      })
    })

    for(let i=0; i <= SalesOffice.length-1;i++){
      // console.log('------------------------',SalesOffice[i].year,SalesOffice[i].month,'-----------------------')
       for(let x=0;x <=SalesOffice[i].ranking.length-1;x++){
        // console.log(SalesOffice[i].ranking[x].EmployeeCode,SalesOffice[i].ranking[x].TH,x+1)
        if(SalesOffice[i].ranking[x].EmployeeCode == obj.EmployeeCode){
            SalesOffice[i].rank = x+1
        }
       }
    }

 

//=======================NationWide===========================================================================

        let NationWide = res.data.reduce((total,item)=>{
          let index = total.findIndex(y =>   y.year == item.year && y.month == item.month)
          if(index >-1){
              total[index].th += item.TH 
              total[index].kakou += 1,
              total[index].avg = Math.round( ( total[index].th/  total[index].kakou)  * 10 ) / 10
              // total[index].avg = total[index].avg == 0  ? 0 : (total[index].avg).toFixed(1),
              total[index].item.push(item)
            return total
          }
          else{  
            total.push(
                {
                  year:item.year,
                  month:item.month,
                  th:item.TH,
                  kakou : 1,
                  avg : item.TH,
                  item:[item],
                }
              )
            return total
          }
        },[])

        NationWide.forEach(rec=>{
            rec.ranking = rec.item.reduce((total,item)=>{
              let index = total.findIndex(y => y.EmployeeCode==item.EmployeeCode )
                if(index > -1){
                    total[index].TH += item.TH
                  return total
                }else{
                  total.push(
                   { 
                    EmployeeCode:item.EmployeeCode,
                    TH:item.TH,
                   }
                  )
                  return total
                }
            },[]).sort((a,b)=>{
              return a.TH - b.TH || a.EmployeeCode - b.EmployeeCode
            })
          })
          for(let i=0; i <= NationWide.length-1;i++){
              // console.log('------------------------',NationWide[i].year,NationWide[i].month,'-----------------------')
            let holdup = NationWide[i].ranking.reduce((total,item)=>{
              let index = total.findIndex(x => x.rankTH == item.TH)
                if(index > -1){
                    total[index].Planners.push(item)
                    return total
                }
                else{
                    total.push({
                      rankTH:item.TH,
                      Planners:[item]
                    })
                    return total
                }
            },[])
            
              let ctr = 0 
              // console.log( '------'+NationWide[i].year +'--'+ NationWide[i].month +'--------')
            holdup.filter((rec,index)=>{      
              // console.log(rec)
              rec.Planners.forEach((r)=>{
                if(r.EmployeeCode == obj.EmployeeCode && index == 0){
                  NationWide[i].rank = index+1
                }
                else if (r.EmployeeCode == obj.EmployeeCode && index > 0){
                  NationWide[i].rank = ctr +1
                }
              })
              ctr +=  rec.Planners.length
            })
         }


        //  for(let i=0; i<=NationWide.length-1;i++){
        //   console.log('-----------',NationWide[i].year, NationWide[i].month,'-----------------------')
        
        // } 




        //  let frstQrt=[]
        //  frstQrt=[...NationWide[0].ranking,...NationWide[1].ranking,...NationWide[2].ranking]
        //  let firstQuarter = frstQrt.reduce((total,item)=>{
        //     let index = total.findIndex(y => y.EmployeeCode==item.EmployeeCode)
        //     if(index > -1){
        //       total[index].TH += item.TH
        //     return total
        //   }else{
        //     total.push(
        //      { 
        //       EmployeeCode:item.EmployeeCode,
        //       TH:item.TH,
        //      }
        //     )
        //     return total
        //   }
        //  },[]).sort((a,b)=>{
        //   return a.TH - b.TH || a.EmployeeCode - b.EmployeeCode
        // })

        // console.log(firstQuarter)

      //    let scndQrt=[]
      //    scndQrt=[...NationWide[3].ranking,...NationWide[4].ranking,...NationWide[5].ranking]
      //    let secondQuarter = scndQrt.reduce((total,item)=>{
      //       let index = total.findIndex(y => y.EmployeeCode==item.EmployeeCode)
      //       if(index > -1){
      //         total[index].TH += item.TH
      //       return total
      //     }else{
      //       total.push(
      //        { 
      //         EmployeeCode:item.EmployeeCode,
      //         TH:item.TH,
      //        }
      //       )
      //       return total
      //     }
      //    },[]).sort((a,b)=>{
      //     return a.TH - b.TH || a.EmployeeCode - b.EmployeeCode
      //   })
      //   console.log(secondQuarter)



      //   let thrQrt=[]
      //   thrQrt=[...NationWide[6].ranking,...NationWide[7].ranking,...NationWide[8].ranking]
      //   let thirdQuarter = thrQrt.reduce((total,item)=>{
      //      let index = total.findIndex(y => y.EmployeeCode==item.EmployeeCode)
      //      if(index > -1){
      //        total[index].TH += item.TH
      //      return total
      //    }else{
      //      total.push(
      //       { 
      //        EmployeeCode:item.EmployeeCode,
      //        TH:item.TH,
      //       }
      //      )
      //      return total
      //    }
      //   },[]).sort((a,b)=>{
      //    return a.TH - b.TH || a.EmployeeCode - b.EmployeeCode
      //  })
      //  console.log(thirdQuarter)


      //  let frtQrt=[]
      //  frtQrt=[...NationWide[9].ranking,...NationWide[10].ranking,...NationWide[11].ranking]
      //   let fourtQuarter = frtQrt.reduce((total,item)=>{
      //      let index = total.findIndex(y => y.EmployeeCode==item.EmployeeCode)
      //      if(index > -1){
      //        total[index].TH += item.TH
      //      return total
      //    }else{
      //      total.push(
      //       { 
      //        EmployeeCode:item.EmployeeCode,
      //        TH:item.TH,
      //       }
      //      )
      //      return total
      //    }
      //   },[]).sort((a,b)=>{
      //    return a.TH - b.TH || a.EmployeeCode - b.EmployeeCode
      //  })
      //  console.log(fourtQuarter)
//=====================================================================================================
               resolve({headers:headers,planner:planner,SalesOffice:SalesOffice,NationWide:NationWide})
            }).catch(err=>{
              if(err.response.status == 403 || err.response.status == 401){
                Swal.fire({
                  icon: "info",
                  title: `Token Expired System will forced logout`,
                  showConfirmButton: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    store.commit("STORE_USERINFO", null);
                    store.commit("STORE_SALESOFFICE", null)
                    store.commit("STORE_CHART_URL", null);
                    router.push("/login");
                  }
                });
              }else{
                resolve(err.message)
              }
            })
        })
    }

}